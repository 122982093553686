// import React from "react";
// import { Link } from "react-router-dom";
// import {
//     Divider,
//     Avatar,
//     Row,
//     Col,
// } from "antd";
// import { RiSettings3Line } from "react-icons/ri";
// import avatar from "../../../../assets/images/memoji/memoji-1.png";

export default function MenuFooter(props) {
    return (null);
};