import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PagesRoutes from "../../../router/routes/Pages";
import { LeftCircleOutlined } from "@ant-design/icons";

export default function Breadcrumb({ collapsed }) {
  const [title, setTitle] = useState("");
  let history = useHistory();
  history.listen((location, action) => {
    const tt = PagesRoutes.find((nav) => nav.id === location.pathname)?.id;
    setTitle(tt);
  });
  return (
    <div
      style={{
        fontWeight: "bold",
        display: "flex",
        marginTop:"10px",
        visibility: window.innerWidth < 547 && !collapsed && "hidden",
      }}
    >
      <div>
        <LeftCircleOutlined
          style={{ fontSize: "22px", marginRight: "5px", paddingTop: "20px" }}
          onClick={() => window.history.back()}
        />
      </div>
      <p style={{ fontSize: "16px", marginRight: "5px" }}>
        {PagesRoutes.find((nav) => nav.id === title)?.title}
      </p>
    </div>
  );
}
