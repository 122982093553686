import axios from "axios";
require("dotenv").config();

const axiosIns = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:5000/api/v1", //"http://18.142.134.212:3000/api/v1", //"http://localhost:3000/api/v1", //process.env.REACT_APP_BASE_URL,
  timeout: 1000000,
  //headers: {'X-Custom-Header': 'foobar'}
});
// Add a request interceptor
axiosIns.interceptors.request.use(
  function (config) {
    // Do something before request is sent
      config.headers.Authorization = `Bearer ${sessionStorage.getItem(
        "token"
      )}`;
    
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosIns.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

const BASEURL =
  process.env.REACT_APP_BASE_URL || "http://localhost:5000/api/v1"; //"http://18.142.134.212:3000/api/v1";

export { axiosIns, BASEURL };
