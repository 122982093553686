// import { Home, Graph, Document, People, Paper } from "react-iconly";
import dashboard from '../assets/images/sidebar/dashboard.svg';
import org from '../assets/images/sidebar/org.svg';
import centralrepo from '../assets/images/sidebar/centralrepo.svg';
import utility from '../assets/images/sidebar/utility.svg';
import additional from '../assets/images/sidebar/additional.svg';
import master from '../assets/images/sidebar/master.svg';
import onboarding from '../assets/images/sidebar/onboarding.svg';
import {
  AiOutlineCalendar,
  AiOutlineContainer,
  AiOutlineFileProtect,
} from "react-icons/ai";

const main = [
  {
    id: "dashboard",
    title: <main className="sidebar-title">Dashboard</main>,
    icon: <img className="sidebar-icons" alt="" src={dashboard} />,
    navLink: "/dashboard",
    role: "SUPER_ADMIN",
  },
  {
    id: "dashboard2",
    title: <main className="sidebar-title">Dashboard</main>,
    icon: <img className="sidebar-icons" alt="" src={dashboard} />,
    navLink: "/dashboard2",
    role: "GROUP_ADMIN",
  },
  {
    id: "dashboard2",
    title: <main className="sidebar-title">Dashboard</main>,
    icon: <img className="sidebar-icons" alt="" src={dashboard} />,
    navLink: "/dashboard2",
    role: "ENTITY_ADMIN",
  },
  {
    id: "dashboard2",
    title: <main className="sidebar-title">Dashboard</main>,
    icon: <img className="sidebar-icons" alt="" src={dashboard} />,
    navLink: "/dashboard2",
    role: "UNIT_ADMIN",
  },
  {
    id: "dashboard2",
    title: <main className="sidebar-title">Dashboard</main>,
    icon: <img className="sidebar-icons" alt="" src={dashboard} />,
    navLink: "/dashboard2",
    role: "L2",
  },
  {
    id: "dashboard2",
    title: <main className="sidebar-title">Dashboard</main>,
    icon: <img className="sidebar-icons" alt="" src={dashboard} />,
    navLink: "/dashboard2",
    role: "L1",
  },
  // {
  //   id: "group",
  //   title: "Group",
  //   icon: <Home set="curved" className="remix-icon" />,
  //   navLink: "/group",
  // },
  {
    id: "calendar",
    title: <main className="sidebar-title">Calendar</main>,
    icon: <AiOutlineCalendar />,
    navLink: "/apps/calendar",
    role: "SUPER_ADMIN",
  },
  {
    id: "calendar",
    title: <main className="sidebar-title">Calendar</main>,
    icon: <AiOutlineCalendar />,
    navLink: "/apps/calendar",
    role: "GROUP_ADMIN",
  },
  {
    id: "calendar",
    title: <main className="sidebar-title">Calendar</main>,
    icon: <AiOutlineCalendar />,
    navLink: "/apps/calendar",
    role: "ENTITY_ADMIN",
  },
  {
    id: "calendar",
    title: <main className="sidebar-title">Calendar</main>,
    icon: <AiOutlineCalendar />,
    navLink: "/apps/calendar",
    role: "UNIT_ADMIN",
  },
  {
    id: "calendar",
    title: <main className="sidebar-title">Calendar</main>,
    icon: <AiOutlineCalendar />,
    navLink: "/apps/calendar",
    role: "L2",
  },
  {
    id: "calendar",
    title: <main className="sidebar-title">Calendar</main>,
    icon: <AiOutlineCalendar />,
    navLink: "/apps/calendar",
    role: "L1",
  },
  // {
  //   id: "organizations",
  //   title: <main className="sidebar-title">Organizations</main>,
  //   icon: <img className="sidebar-icons" alt="" src={org} />,
  //   navLink: "/organizations",
  //   role: "SUPER_ADMIN",
  // },
  {
    id: "centralRepository",
    title: <main className="sidebar-title">Central repository</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/central-repository",
    role: "SUPER_ADMIN",
  },
  {
    id: "reports",
    title: <main className="sidebar-title">Reports</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/reports",
    role: "SUPER_ADMIN",
  },
  {
    id: "reports",
    title: <main className="sidebar-title">Reports</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/reports",
    role: "GROUP_ADMIN",
  },
  {
    id: "reports",
    title: <main className="sidebar-title">Reports</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/reports",
    role: "ENTITY_ADMIN",
  },
  {
    id: "reports",
    title: <main className="sidebar-title">Reports</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/reports",
    role: "UNIT_ADMIN",
  },
  {
    id: "reports",
    title: <main className="sidebar-title">Reports</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/reports",
    role: "AUDITOR",
  },
  {
    id: "reports",
    title: <main className="sidebar-title">Reports</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/reports",
    role: "L2",
  },
  {
    id: "reports",
    title: <main className="sidebar-title">Reports</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/reports",
    role: "L1",
  },
  {
    id: "directory",
    title: <main className="sidebar-title">Asset/Device tracker</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/directory",
    role: "SUPER_ADMIN",
  },
  {
    id: "directory",
    title: <main className="sidebar-title">Asset/Device tracker</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/directory",
    role: "GROUP_ADMIN",
  },
  {
    id: "directory",
    title: <main className="sidebar-title">Asset/Device tracker</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/directory",
    role: "ENTITY_ADMIN",
  },
  {
    id: "directory",
    title: <main className="sidebar-title">Asset/Device tracker</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/directory",
    role: "UNIT_ADMIN",
  },
  {
    id: "directory",
    title: <main className="sidebar-title">Asset/Device tracker</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/directory",
    role: "L2",
  },
  {
    id: "directory",
    title: <main className="sidebar-title">Asset/Device tracker</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/directory",
    role: "L1",
  },
  {
    id: "product-standard",
    title: <main className="sidebar-title">Products Standards</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/product-standard",
    role: "SUPER_ADMIN",
  },
  {
    id: "product-standard",
    title: <main className="sidebar-title">Products Standards</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/product-standard",
    role: "GROUP_ADMIN",
  },
  {
    id: "product-standard",
    title: <main className="sidebar-title">Products Standards</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/product-standard",
    role: "ENTITY_ADMIN",
  },
  {
    id: "product-standard",
    title: <main className="sidebar-title">Products Standards</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/product-standard",
    role: "UNIT_ADMIN",
  },
  {
    id: "product-standard",
    title: <main className="sidebar-title">Products Standards</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/product-standard",
    role: "L2",
  },
  {
    id: "product-standard",
    title: <main className="sidebar-title">Products Standards</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    navLink: "/product-standard",
    role: "L1",
  },
  {
    id: "docs",
    title: <main className="sidebar-title">Document Library</main>,
    icon: <img className="sidebar-icons" alt="" src={utility} />,
    navLink: "/docs-lib",
    role: "SUPER_ADMIN",
  },
  {
    id: "docs",
    title: <main className="sidebar-title">Document Library</main>,
    icon: <img className="sidebar-icons" alt="" src={utility} />,
    navLink: "/docs-lib",
    role: "GROUP_ADMIN",
  },
  {
    id: "docs",
    title: <main className="sidebar-title">Document Library</main>,
    icon: <img className="sidebar-icons" alt="" src={utility} />,
    navLink: "/docs-lib",
    role: "ENTITY_ADMIN",
  },
  {
    id: "docs",
    title: <main className="sidebar-title">Document Library</main>,
    icon: <img className="sidebar-icons" alt="" src={utility} />,
    navLink: "/docs-lib",
    role: "UNIT_ADMIN",
  },
  {
    id: "docs",
    title: <main className="sidebar-title">Document Library</main>,
    icon: <img className="sidebar-icons" alt="" src={utility} />,
    navLink: "/docs-lib",
    role: "L2",
  },
  {
    id: "docs",
    title: <main className="sidebar-title">Document Library</main>,
    icon: <img className="sidebar-icons" alt="" src={utility} />,
    navLink: "/docs-lib",
    role: "L1",
  },
  // {
  //   id: "utility",
  //   title: <main className="sidebar-title">Tasks</main>,
  //   icon: <img className="sidebar-icons" alt="" src={utility} />,
  //   navLink: "/tasks",
  //   role: "SUPER_ADMIN",
  // },
  // {
  //   id: "additionalServices",
  //   title: <main className="sidebar-title">Additional services</main>,
  //   icon: <img className="sidebar-icons" alt="" src={additional} />,
  //   navLink: "/additional-services",
  //   role: "SUPER_ADMIN",
  // },

  {
    id: "Master",
    title: <main>Master</main>,
    icon: <img className="sidebar-icons" alt="" src={master} />,
    navLink: "/master",
    role: "SUPER_ADMIN",
  },
  {
    id: "Master",
    title: <main>Master</main>,
    icon: <img className="sidebar-icons" alt="" src={master} />,
    navLink: "/master",
    role: "GROUP_ADMIN",
  },
  {
    id: "Master",
    title: <main>Master</main>,
    icon: <img className="sidebar-icons" alt="" src={master} />,
    navLink: "/master",
    role: "ENTITY_ADMIN",
  },
  {
    id: "Master",
    title: <main>Master</main>,
    icon: <img className="sidebar-icons" alt="" src={master} />,
    navLink: "/master",
    role: "UNIT_ADMIN",
  },
  {
    id: "Master",
    title: <main>Master</main>,
    icon: <img className="sidebar-icons" alt="" src={master} />,
    navLink: "/master",
    role: "L2",
  },

  {
    id: "Onboarding",
    title: <main>Onboarding</main>,
    icon: <img className="sidebar-icons" alt="" src={onboarding} />,
    navLink: "/onboarding",
    role: "SUPER_ADMIN",
  },
  {
    id: "Onboarding-unit",
    title: <main>Onboarding</main>,
    icon: <img className="sidebar-icons" alt="" src={onboarding} />,
    navLink: "/onboarding-unit",
    role: "GROUP_ADMIN",
  },
  {
    id: "Onboarding-unit",
    title: <main>Onboarding</main>,
    icon: <img className="sidebar-icons" alt="" src={onboarding} />,
    navLink: "/onboarding-unit",
    role: "ENTITY_ADMIN",
  },
  {
    id: "Onboarding-unit",
    title: <main>Onboarding</main>,
    icon: <img className="sidebar-icons" alt="" src={onboarding} />,
    navLink: "/onboarding-unit",
    role: "UNIT_ADMIN",
  },

  // {
  //   id: "Compliance Repository",
  //   title: <main>Compliance Repository</main>,
  //   icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
  //   navLink: "/compliance-repository",
  //   role: "ENTITY_ADMIN",
  // },
  {
    id: "Entity Compliance Repository",
    title: <main>Manage Compliance</main>,
    icon: <AiOutlineCalendar />,
    navLink: "/manage-compliances",
    role: "ENTITY_ADMIN",
  },
  {
    id: "Entity Compliance Repository",
    title: <main>Manage Compliance</main>,
    navLink: "/manage-compliances",
    icon: <AiOutlineCalendar />,
    role: "UNIT_ADMIN",
  },
  {
    id: "Entity Compliance Repository",
    title: <main>Manage Compliance</main>,
    icon: <AiOutlineCalendar />,
    navLink: "/manage-compliances",
    role: "L2",
  },
  {
    id: "User Compliance Repository",
    title: <main>Compliance Repo</main>,
    icon: <img className="sidebar-icons" alt="" src={centralrepo} />,
    icon: <AiOutlineFileProtect />,
    role: "GROUP_ADMIN",
  },
  {
    id: "User Compliance Repository",
    title: <main>Compliance Repo</main>,
    icon: <AiOutlineFileProtect />,
    navLink: "/user-compliance-repository",
    role: "ENTITY_ADMIN",
  },
  {
    id: "User Compliance Repository",
    title: <main>Compliance Repo</main>,
    icon: <AiOutlineFileProtect />,
    navLink: "/user-compliance-repository",
    role: "UNIT_ADMIN",
  },
  {
    id: "User Compliance Repository",
    title: <main>Compliance Repo</main>,
    icon: <AiOutlineFileProtect />,
    navLink: "/user-compliance-repository",
    role: "AUDITOR",
  },
  {
    id: "User Compliance Repository",
    title: <main>Compliance Repo</main>,
    icon: <AiOutlineFileProtect />,
    navLink: "/user-compliance-repository",
    role: "L2",
  },
  {
    id: "User Compliance Repository",
    title: <main>Compliance Repo</main>,
    icon: <AiOutlineFileProtect />,
    navLink: "/user-compliance-repository",
    role: "L1",
  },
];

export default main;
