import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Modal,
  Upload,
  message,
} from "antd";
import { AuthService } from "../../../../api/Auth";
import { useHistory } from "react-router-dom";
import { RiCloseFill, RiErrorWarningFill } from "react-icons/ri";

import loginhr from "../../../../assets/images/pages/login/login_hr.png";
import Cookies from "js-cookie";
import CM from "../../../../assets/images/logo/logo.png";

import "./login.css";
import { post } from "../../../../api/HTTPService";
import TextArea from "antd/lib/input/TextArea";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
require("dotenv").config();

export default function LoginForm() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [whistleForm] = Form.useForm();
  const [selectedFile, setSelectedFile] = React.useState({
    file: "",
    fileName: "",
  });
  //const [errorMessage, setErrorMessage] = useState();
  const [showWhistle, setshowWhistle] = useState(false);

  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    AuthService.login({
      email: values.email,
      password: values.password,
      user_type: "SUPER_ADMIN",
    })
      .then((response) => {
        // Success logic

        if (response && response.jwt_token) {
          Cookies.set("token", response.jwt_token, { domain: ".esgfit.in" });
          Cookies.set("name", response.name, { domain: ".esgfit.in" });
          Cookies.set("currentUserRole", response.user_role, {
            domain: ".esgfit.in",
          });
          sessionStorage.setItem("token", response.jwt_token);
          sessionStorage.setItem("name", response.name);
          sessionStorage.setItem("currentUserRole", response.user_role);
          setLoading(false);
          switch (response.user_role) {
            case "SUPER_ADMIN":
              history.push("/dashboard");
              break;
            case "GROUP_ADMIN":
            case "UNIT_ADMIN":
            case "L2":
            case "L1":
              history.push("/user-compliance-repository");
              break;
            case "ENTITY_ADMIN":
              history.push("/onboarding-unit");
              break;
          }

          window.location.reload();
        } else {
          throw Error();
        }
      })
      .catch((error) => {
        notification.open({
          message: "Error",
          description:
            error.response.status === 401
              ? error.response.data.data
              : "Something went wrong",
          icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
          closeIcon: (
            <RiCloseFill
              className="remix-icon da-text-color-black-80"
              size={24}
            />
          ),
        });
        setLoading(false);
      });
  };
  const sso = () => {
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "https://login.xecurify.com/moas/broker/login/jwt/292227?client_id=y8xUiGfCVuS2Hp52&redirect_uri=http://localhost:3000/"
        : "https://login.xecurify.com/moas/broker/login/jwt/292227?client_id=epGmJWZy5q0lc88e&redirect_uri=https://compliance-tool.netlify.app/"; //
  };

  // The SearchParams hook lets us work with the query string of a URL.

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    console.log("search", searchParams, window.location.search);
    // Gets the id_token parameter from the query URL
    let jwt = searchParams.get("id_token");
    console.log("jwt", process.env.NODE_ENV);
    console.log("jwt", jwt);
    if (jwt) {
      sessionStorage.setItem("token", jwt);
      onFinish();
    }
  }, []);

  const sendMail = async (values) => {
    console.log("form avl", values);
    const formData = new FormData();
    formData.append("name", values.name);

    formData.append("subject", values.subject);
    formData.append("greviance", values.greviance);
    formData.append("file", selectedFile.file);
    const res = await post("/whistle/send", formData);
    setshowWhistle(false);

    if (res) {
      notification.open({
        message: "Success",
        description: "Email Has been sent",
        icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
        closeIcon: (
          <RiCloseFill
            className="remix-icon da-text-color-black-80"
            size={24}
          />
        ),
      });
    }
  };
  const getFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <div>
      <Card className="login-card">
        <img src={loginhr} className="login_hr" alt="hr" />
        <Row className="da-h-100" align="middle" justify="center">
          <Col className="da-px-sm-8 da-pt-24 da-pb-48">
            <div>
              <img src={CM} alt="" />
            </div>
            <p className="da-mt-sm-0 da-mt-8 da-text-color-black-60">
              Welcome back, please login to your account.
            </p>

            {/* <Form
              form={form}
              name="register"
              onFinish={sso}
              initialValues={{
                residence: ["zhejiang", "hangzhou", "xihu"],
                prefix: "86",
              }}
              scrollToFirstError
            >
              

              <Form.Item>
                <Button
                  className="full-width"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Single Sign On
                </Button>
              </Form.Item>
            </Form> */}

            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={{
                residence: ["zhejiang", "hangzhou", "xihu"],
                prefix: "86",
              }}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button
                  className="full-width"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Sign In
                </Button>
              </Form.Item>
            </Form>

            {/*<Col className="da-form-info">*/}
            {/*  <span*/}
            {/*    className="da-text-color-black-80 da-caption da-mr-4"*/}
            {/*    style={{ cursor: "pointer" }}*/}
            {/*    onClick={() => setshowWhistle(true)}*/}
            {/*  >*/}
            {/*    Whistleblower Access*/}
            {/*  </span>*/}

            {/*  /!*<Link*/}
            {/*    className="da-text-color-primary-1 da-caption"*/}
            {/*    to="/pages/authentication/register"*/}
            {/*  >*/}
            {/*    Create an account*/}
            {/*  </Link> *!/*/}
            {/*</Col>*/}
          </Col>
        </Row>
      </Card>
      {/*<Modal*/}
      {/*  title="WhistleBlower"*/}
      {/*  centered*/}
      {/*  visible={showWhistle}*/}
      {/*  width={700}*/}
      {/*  footer={[]}*/}
      {/*  onCancel={() => setshowWhistle(false)}*/}
      {/*>*/}
      {/*  <Form*/}
      {/*    form={whistleForm}*/}
      {/*    name="register"*/}
      {/*    onFinish={sendMail}*/}
      {/*    scrollToFirstError*/}
      {/*  >*/}
      {/*    <Form.Item name="name">*/}
      {/*      <Input placeholder="Name of the person" />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item name="subject">*/}
      {/*      <Input placeholder="Subject matter" />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item name="greviance">*/}
      {/*      <TextArea*/}
      {/*        rows={4}*/}
      {/*        placeholder="Grievance/ issue for which whistleblowing is being done"*/}
      {/*      />*/}
      {/*    </Form.Item>*/}
      {/*    <label>Attachments</label>*/}
      {/*    <Upload*/}
      {/*      onChange={(info) => {*/}
      {/*        if (info.file.status !== "uploading") {*/}
      {/*          console.log(info.file, info.fileList);*/}
      {/*        }*/}
      {/*        if (info.file.status === "done") {*/}
      {/*          message.success(`${info.file.name} file uploaded successfully`);*/}
      {/*          setSelectedFile((prevVal) => ({*/}
      {/*            ...prevVal,*/}
      {/*            file: info.file.originFileObj,*/}
      {/*            fileName: info.file.name,*/}
      {/*          }));*/}
      {/*        }*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <Button icon={<UploadOutlined />}>Click to Upload</Button>*/}
      {/*    </Upload>*/}
      {/*    <Form.Item>*/}
      {/*      <Button className="full-width" type="primary" htmlType="submit">*/}
      {/*        Send*/}
      {/*      </Button>*/}
      {/*    </Form.Item>*/}
      {/*  </Form>*/}
      {/*</Modal>*/}
    </div>
  );
}
