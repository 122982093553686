import { notification, Modal, Form, Input, Button } from "antd";
import { useState, useEffect } from "react";
import { RiCloseFill, RiErrorWarningFill } from "react-icons/ri";
import { AuthService } from "../../../../api/Auth";

const ResetPassword = ({ reset, setReset }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
        console.log("values", values);

    if (values.password != values.confirm_password) {
      notification.open({
        message: "Password do not match",
        icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
        closeIcon: (
          <RiCloseFill
            className="remix-icon da-text-color-black-80"
            size={24}
          />
        ),
      });
      setLoading(false);
      return;
    }
    if (!values.OTP) {
      notification.open({
        message: "Please enter otp",
        icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
        closeIcon: (
          <RiCloseFill
            className="remix-icon da-text-color-black-80"
            size={24}
          />
        ),
      });
      setLoading(false);
      return;
    }
    AuthService.reset({
      otp: values.OTP,
      password: values.password,
      user_type: "SUPER_ADMIN",
    })
      .then((response) => {
        // Success logic

        if (response) {
          sessionStorage.removeItem("token");
          window.location.reload();
        } else {
          throw Error();
        }
      })
      .catch((error) => {
        notification.open({
          message: "Error",
          description:
            error.response.status === 401
              ? error.response.data.data
              : "Something went wrong",
          icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
          closeIcon: (
            <RiCloseFill
              className="remix-icon da-text-color-black-80"
              size={24}
            />
          ),
        });
        setLoading(false);
      });
  };

  const resendOTP = () => {
    AuthService.resend()
      .then((response) => {
        // Success logic
        notification.open({
          message: "Success",
          description: "OTP sent to your email",
          closeIcon: (
            <RiCloseFill
              className="remix-icon da-text-color-black-80"
              size={24}
            />
          ),
        });
      })
      .catch((error) => {
        notification.open({
          message: "Error",
          description:
            error.response.status === 401
              ? error.response.data.data
              : "Something went wrong",
          icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
          closeIcon: (
            <RiCloseFill
              className="remix-icon da-text-color-black-80"
              size={24}
            />
          ),
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (reset) {
      resendOTP();
    }
  }, [reset]);

  return (
    <Modal centered visible={reset} onCancel={() => setReset(false)}>
      <Form form={form} name="basic" onFinish={onFinish} scrollToFirstError>
        <Form.Item
          name="OTP"
          rules={[
            {
              required: true,
              message: "Please enter your otp!",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="Enter OTP" />
          
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>

        <Form.Item
          name="confirm_password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Button
            className="full-width"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPassword;
