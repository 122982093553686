import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/dashboard",
    id: "/dashboard",
    title: <div>Dashboard</div>,
    component: lazy(() => import("../../view/main/dashboard/analytics")),
    layout: "VerticalLayout",
    role: "SUPER_ADMIN",
  },
  {
    path: "/dashboard2",
    id: "/dashboard2",
    title: <div>Dashboard</div>,
    component: lazy(() => import("../../view/main/dashboard2")),
    layout: "VerticalLayout",
    roles: ["GROUP_ADMIN", "ENTITY_ADMIN", "UNIT_ADMIN", "L2", "L1"],
  },
  {
    path: "/organizations",
    id: "/organizations",
    title: <div>Organizations</div>,
    component: lazy(() => import("../../view/pages/errors/coming-soon")),
    layout: "VerticalLayout",
    role: "SUPER_ADMIN",
  },
  {
    path: "/central-repository",
    id: "/central-repository",
    title: <div>Central repository</div>,
    component: lazy(() => import("../../view/main/centralRepo")),
    layout: "VerticalLayout",
    role: "SUPER_ADMIN",
  },
  {
    path: "/reports",
    id: "/reports",
    title: <div>Reports</div>,
    component: lazy(() => import("../../view/main/reports")),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "AUDITOR",
      "L2",
      "L1",
    ],
  },
  {
    path: "/risk-report",
    id: "/risk-report",
    title: <div>Risk Report</div>,
    component: lazy(() => import("../../view/main/riskReport")),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "AUDITOR",
      "L2",
      "L1",
    ],
  },
  {
    path: "/compliance-report-logs",
    id: "/compliance-report-logs",
    title: <div>Compliance Reporting Logs</div>,
    component: lazy(() => import("../../view/main/reportLogs")),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "AUDITOR",
      "L2",
      "L1",
    ],
  },
  {
    path: "/directory",
    id: "/directory",
    title: <div>Asset/ Device tracker</div>,
    component: lazy(() => import("../../view/main/directory/folder-section")),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "L2",
      "L1",
    ],
    exact: true,
  },
  {
    path: "/directory/:id",
    id: "/sub-directory",
    title: <div>Asset/ Device tracker Sub Directories</div>,
    component: lazy(() =>
      import("../../view/main/directory/sub-folder-section")
    ),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "L2",
      "L1",
    ],
  },
  {
    path: "/sub-directory/:id",
    id: "/files",
    title: <div>Files</div>,
    component: lazy(() => import("../../view/main/directory/files-section")),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "L2",
      "L1",
    ],
  },
  {
    path: "/product-standard",
    id: "/product-standard",
    title: <div>Product Standard</div>,
    component: lazy(() =>
      import("../../view/main/productLibrary/folder-section")
    ),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "L2",
      "L1",
    ],
    exact: true,
  },
  {
    path: "/product-standard/:id",
    id: "/sub-product-standard",
    title: <div>Sub Folder Product Standard</div>,
    component: lazy(() =>
      import("../../view/main/productLibrary/sub-folder-section")
    ),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "L2",
      "L1",
    ],
  },
  {
    path: "/sub-product-standard/:id",
    id: "/files",
    title: <div>Files</div>,
    component: lazy(() =>
      import("../../view/main/productLibrary/files-section")
    ),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "L2",
      "L1",
    ],
  },
  {
    path: "/docs-lib",
    id: "/docs-lib",
    title: <div>Documents Library</div>,
    component: lazy(() => import("../../view/main/docsLibrary")),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "L2",
      "L1",
    ],
    exact: true,
  },
  {
    path: "/view-central-repository",
    id: "/view-central-respository",
    title: <div></div>,
    component: lazy(() =>
      import("../../view/main/centralRepo/components/Repotable")
    ),
    layout: "VerticalLayout",
    role: "SUPER_ADMIN",
  },
  // {
  //   path: "/parent-child-tagging/:id",
  //   id: "/parent-child-tagging/0",
  //   title: <div>Parent child tagging</div>,
  //   component: lazy(() => import("../../view/main/parentChildTagging")),
  //   layout: "VerticalLayout",
  //   role: "SUPER_ADMIN",
  // },
  {
    path: "/manage-compliances",
    id: "/manage-compliances",
    title: <div>Manage compliances</div>,
    component: lazy(() => import("../../view/main/manageCompliance")),
    layout: "VerticalLayout",
    roles: ["SUPER_ADMIN", "GROUP_ADMIN", "ENTITY_ADMIN", "UNIT_ADMIN", "L2"],
  },
  // {
  //   path: "/tasks",
  //   id: "/tasks",
  //   title: <div>Tasks</div>,
  //   component: lazy(() => import("../../view/main/utility")),
  //   layout: "VerticalLayout",
  //   role: "SUPER_ADMIN",
  // },

  {
    path: "/apps/calendar",
    id: "/app-calendar",
    title: <div>Calendar</div>,
    component: lazy(() => import("../../view/apps/calendar")),
    layout: "VerticalLayout",
    roles: [
      "SUPER_ADMIN",
      "GROUP_ADMIN",
      "ENTITY_ADMIN",
      "UNIT_ADMIN",
      "AUDITOR",
      "L2",
      "L1",
    ],
  },
  // {
  //   path: "/additional-services",
  //   id: "/additional-services",
  //   title: <div>Additional services</div>,
  //   component: lazy(() => import("../../view/pages/errors/coming-soon")),
  //   layout: "VerticalLayout",
  //   role: "SUPER_ADMIN",
  // },
  {
    path: "/master",
    id: "/master",
    title: <div>Master</div>,
    component: lazy(() => import("../../view/main/master/")),
    layout: "VerticalLayout",
    roles: ["SUPER_ADMIN", "GROUP_ADMIN", "ENTITY_ADMIN", "UNIT_ADMIN", "L2"],
  },
  {
    path: "/onboarding",
    id: "/onboarding",
    title: <div>Onboarding</div>,
    component: lazy(() => import("../../view/main/onboarding/")),
    layout: "VerticalLayout",
    role: "SUPER_ADMIN",
  },
  {
    path: "/onboarding-unit",
    id: "/onboarding-unit",
    title: <div>Onboarding</div>,
    component: lazy(() => import("../../view/main/onboardingUnit/")),
    layout: "VerticalLayout",
    roles: ["SUPER_ADMIN", "GROUP_ADMIN", "ENTITY_ADMIN", "UNIT_ADMIN"],
  },
  // {
  //   path: "/compliance-repository",
  //   id: "/compliance-repository",
  //   title: <div>Compliance Repository</div>,
  //   component: lazy(() => import("../../view/main/complianceRepository/")),
  //   layout: "VerticalLayout",
  //   role: "ENTITY_ADMIN",
  // },
  {
    path: "/compliance-repository",
    id: "/compliance-repository",
    title: <div>Compliance Repository</div>,
    component: lazy(() => import("../../view/main/complianceRepository/")),
    layout: "VerticalLayout",
    roles: ["GROUP_ADMIN", "ENTITY_ADMIN", "UNIT_ADMIN","AUDITOR", "L2", "L1"],
  },
  {
    path: "/user-compliance-repository",
    id: "/user-compliance-repository",
    title: <div>Compliance Repository</div>,
    component: lazy(() => import("../../view/main/complianceRepositoryUser/")),
    layout: "VerticalLayout",
    roles: ["GROUP_ADMIN", "ENTITY_ADMIN", "UNIT_ADMIN","AUDITOR", "L2", "L1"],
  },
];

export default PagesRoutes;
