import Router from "./router/Router";
import "../src/App.css";
import { useEffect } from "react";

export default function App() {
  useEffect(() => {
    const initialValue = document.body.style.zoom;

    // document.body.style.zoom = "90%";

    return () => {
      // document.body.style.zoom = initialValue;
    };
  }, []);

  return <Router />;
}
