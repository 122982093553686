import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { Layout, Button } from "antd";
import { RiArrowUpLine } from "react-icons/all";

import MenuHeader from "./components/header";
import Sidebar from "./components/menu/Sidebar";

const { Content } = Layout;

export default function VerticalLayout({ children }) {
  const [visible, setVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [contentClass, setContentClass] = useState("");
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setContentClass("sider-margin");
      } else if (window.innerWidth > 800 && collapsed) {
        setContentClass("sider-margin");
      } else {
        setContentClass("sider-margin2");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [collapsed]);

  useEffect(() => {
    // Force a re-render when the route changes
    setContentClass((prev) => prev + " ");
  }, [location]);

  return (
    <Layout className="da-app-layout">
      <Sidebar
        visible={visible}
        setVisible={setVisible}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
      />

      <Layout>
        <MenuHeader setVisible={setVisible} collapsed={collapsed} />

        <Content className={`da-content-main ${contentClass}`}>
          {children}
        </Content>
      </Layout>

      <div className="scroll-to-top">
        <ScrollToTop showUnder={300} style={{ bottom: "5%" }}>
          <Button
            className="da-primary-shadow"
            type="primary"
            shape="circle"
            icon={<RiArrowUpLine />}
          />
        </ScrollToTop>
      </div>
    </Layout>
  );
}
